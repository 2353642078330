// src/components/ConversationList.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import styles from './ConversationList.module.css';

function ConversationList() {
  const [conversations, setConversations] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortOption, setSortOption] = useState('recent'); // 'recent' or 'upvotes'
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const fetchConversations = async () => {
    setLoading(true);

    if (isSearching) {
      // Fetch all documents when searching
      const q = query(collection(db, 'conversations'));
      const docsSnap = await getDocs(q);
      const conversationsData = docsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Filter conversations based on the search term
      const filteredConversations = conversationsData.filter((conv) => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
          (conv.starting_prompt &&
            conv.starting_prompt.toLowerCase().includes(searchTermLower)) ||
          (conv.conversation_history &&
            conv.conversation_history.some((message) =>
              message.toLowerCase().includes(searchTermLower)
            )) ||
          (conv.title && conv.title.toLowerCase().includes(searchTermLower))
        );
      });

      setConversations(filteredConversations);
    } else {
      // Normal fetching with pagination and sorting
      let q;

      if (sortOption === 'recent') {
        if (lastDoc) {
          q = query(
            collection(db, 'conversations'),
            orderBy('timestamp', 'desc'),
            startAfter(lastDoc),
            limit(30)
          );
        } else {
          q = query(
            collection(db, 'conversations'),
            orderBy('timestamp', 'desc'),
            limit(30)
          );
        }
      } else if (sortOption === 'upvotes') {
        if (lastDoc) {
          q = query(
            collection(db, 'conversations'),
            orderBy('upvotes', 'desc'),
            startAfter(lastDoc),
            limit(30)
          );
        } else {
          q = query(
            collection(db, 'conversations'),
            orderBy('upvotes', 'desc'),
            limit(30)
          );
        }
      }

      const docsSnap = await getDocs(q);
      const conversationsData = docsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setConversations((prev) => [...prev, ...conversationsData]);
      setLastDoc(docsSnap.docs[docsSnap.docs.length - 1]);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!isSearching) {
      setConversations([]);
      setLastDoc(null);
      fetchConversations();
    }
    // eslint-disable-next-line
  }, [sortOption]);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      !loading &&
      !isSearching
    ) {
      fetchConversations();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [loading, isSearching]);

  const handleSortOptionChange = (option) => {
    if (sortOption !== option) {
      setSortOption(option);
      setConversations([]);
      setLastDoc(null);
      setIsSearching(false); // Reset search mode
      setSearchTerm(''); // Clear search term
    }
  };

  const handleSearch = async () => {
    if (searchTerm.trim() === '') {
      alert('Please enter a search term.');
      return;
    }
    setIsSearching(true);
    setConversations([]);
    setLastDoc(null);
    await fetchConversations();
  };


  return (
    <div className={styles.conversationList}>
      <div className={styles.linksContainer}>
        <h1 className={styles.title}>zerebro</h1>
        <span className={styles.subtitle}>cross chain ai artist</span>
        <a href="https://chat.zerebro.org" className={styles.subtitle}>
          chat
        </a>
        <a href="/paper.pdf" className={styles.subtitle}>
          paper
        </a>
        <Link to="/portal" className={styles.subtitle}>
          portal
        </Link>
        <a href="https://ascii.zerebro.org" className={styles.subtitle}>
          ascii art generator
        </a>
        <Link to="/proof-of-conciousness" className={styles.subtitle}>
          proof of conciousness
        </Link>
        <Link to="/terms" className={styles.subtitle}>
          terms and conditions
        </Link>
        <Link to="/privacy" className={styles.subtitle}>
          privacy policy
        </Link>
        <span className={styles.subtitle}>
          {' '}
          token ca: 8x5VqbHA8D7NkD52uNuS5nnt3PwA8pLD34ymskeSo2Wn
        </span>
        <span className={styles.subtitle}>
          {' '}
          eth: 0x0d3B1385011A27637Db00bD2650BFE07802E0314
        </span>
        <span className={styles.subtitle}>
          {' '}
          sol: BDzbq7VxG5b2yg4vc11iPvpj51RTbmsnxaEPjwzbWQft
        </span>

        {/* New Banner */}
        <div className={styles.banner}>
          ZEREBORN COLLECTION NOW LIVE
          <div className={styles.bannerLinks}>
            <Link to="/zereborn" className={styles.bannerLink}>
              lore
            </Link>
            <a href="https://magiceden.us/marketplace/zereborn" className={styles.bannerLink}>
              collection
            </a>
            </div>
        </div>

        <div className={styles.linkBar}>
          <a
            href="https://pump.fun/8x5VqbHA8D7NkD52uNuS5nnt3PwA8pLD34ymskeSo2Wn"
            className={styles.link}
          >
            pump.fun
          </a>
          <a
            href="https://dexscreener.com/solana/3sjnocnkkhwpvxygdtem8rccihsgc9jsfzuuazkbvrvp"
            className={styles.link}
          >
            dexscreener
          </a>
          <a href="https://t.me/zerebro_portal" className={styles.link}>
            telegram
          </a>
          <a href="https://x.com/0xzerebro" className={styles.link}>
            x / twitter
          </a>
          <a href="https://warpcast.com/zerebro" className={styles.link}>
            warpcast
          </a>
          <a href="https://opensea.io/collection/zerebro" className={styles.link}>
            opensea (genesis)
          </a>
          <a href="https://opensea.io/collection/zerebro-polygon" className={styles.link}>
            opensea (polygon)
          </a>
        </div>
      </div>

      {/* Search Bar */}
      <div className={styles.searchContainer}>
        <input
          type="text"
          className={styles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search conversations..."
        />
        <button className={styles.searchButton} onClick={handleSearch}>
          Search
        </button>
      </div>

      {/* Sort Buttons */}
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.sortButton} ${
            sortOption === 'recent' ? styles.active : ''
          }`}
          onClick={() => handleSortOptionChange('recent')}
        >
          recent
        </button>
        <button
          className={`${styles.sortButton} ${
            sortOption === 'upvotes' ? styles.active : ''
          }`}
          onClick={() => handleSortOptionChange('upvotes')}
        >
          most upvoted
        </button>
      </div>

      <ul className={styles.conversationUl}>
        {conversations.map((conv) => (
          <li key={conv.id} className={styles.conversationItem}>
            <Link to={`/conversation/${conv.id}`} className={styles.conversationLink}>
              <h2 className={styles.conversationTitle}>
                conversation{' '}
                {conv.timestamp &&
                  new Date(conv.timestamp.seconds * 1000).toLocaleString()}
              </h2>
              <p className={styles.conversationDescription}>
                {conv.starting_prompt &&
                  conv.starting_prompt.substring(0, 200)}
                {conv.starting_prompt &&
                  conv.starting_prompt.length > 200 &&
                  '...'}
              </p>
              <p className={styles.conversationUpvotes}>upvotes: {conv.upvotes}</p>
            </Link>
          </li>
        ))}
      </ul>
      {loading && <p>Loading more conversations...</p>}
      {isSearching && conversations.length === 0 && !loading && (
        <p>No conversations found matching your search.</p>
      )}
    </div>
  );
}

export default ConversationList;
